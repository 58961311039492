import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import "./assets/sass/style.scss";
import "./app.css";

import {
  RegisterLayout,
  Register,
  Onboarding,
  Step1,
  Step3,
  Step4,
  LoginLayout,
  UserLogin,
  ForgotPassword,
  ExamLogin,
} from "./pages/auth";

import {
  ParentDashboard,
  ParentDashboardLayout,
  RegisteredStudents,
  SchoolDashboard,
  SchoolDashboardLayout,
  StudentLayout,
} from "./pages/dashboard";

import {
  LandingLayout,
  StudentPage,
  ParentPage,
  EducatorPage,
  OlympiadLanding,
  AboutPage,
  Terms,
  PrivacyPolicy,
  ContactLanding,
  FaqLanding,
  SyllabusLanding,
  ExamLanding,
  AwardsLanding,
  // CommingSoon,
  InstructionsLanding,
} from "./pages/landingPage";

import {
  ContentCreationLayout,
  AllQuestions,
  UploadQuestion,
} from "./pages/contentCreation";

import {
  ExamInstructions,
  ExamPage,
  Setup,
  //
} from "./pages/examModule";

import Test from "./pages/Test.jsx";
import PageNotFound from "./pages/PageNotFound.jsx";
import SchoolLandingPage from "./pages/landingPage/SchoolPage.jsx";
import usePageViews from "./hooks/UsePageViews.js";
import DashobardLinks from "./pages/dashboard/DashobardLinks.jsx";

const App = () => {
  usePageViews();
  return (
    <>
      <Routes>
        {/* Landing Pages Routes */}
        <Route path="/" element={<LandingLayout />}>
          <Route index element={<StudentPage />} />
          <Route path="studentlanding" element={<StudentPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="parentlanding" element={<ParentPage />} />
          <Route path="educatorlanding" element={<EducatorPage />} />
          <Route path="olympiadLanding" element={<OlympiadLanding />} />
          <Route path="privacy-policy.html" element={<PrivacyPolicy />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="contactus" element={<ContactLanding />} />
          <Route path="schoollanding" element={<SchoolLandingPage />} />
        </Route>

        <Route path="/" element={<DashobardLinks />}>
          <Route path="faqLanding" element={<FaqLanding />} />
          <Route path="syllabusLanding" element={<SyllabusLanding />} />
          <Route path="examLanding" element={<ExamLanding />} />
          <Route path="awardsLanding" element={<AwardsLanding />} />
          <Route path="instructions" element={<InstructionsLanding />} />
        </Route>

        {/* Registration Routes */}
        <Route path="register" element={<RegisterLayout />}>
          <Route index element={<Register />} />
          <Route path="school" element={<Register />}>
            <Route path="setPassword" element={<Register />} />
          </Route>

          <Route path="individual" element={<Register />}>
            <Route path=":schoolId" element={<Register />} />
          </Route>
        </Route>

        {/* Login Routes */}
        <Route path="login" element={<LoginLayout />}>
          <Route index element={<UserLogin />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
        </Route>

        {/* Onboarding Routes */}
        <Route path="onboarding" element={<Onboarding />}>
          <Route index element={<Step1 />} />
          <Route path="step1" element={<Step1 />} />
          <Route path="step2" element={<Step3 />} />
          <Route path="success" element={<Step4 />} />
        </Route>

        {/* Dashboard Routes */}
        <Route path="schoolDashboard" element={<SchoolDashboardLayout />}>
          <Route index element={<SchoolDashboard />} />
          <Route path="registeredStudents" element={<Outlet />}>
            <Route index element={<RegisteredStudents />} />
            <Route path=":studentId" element={<StudentLayout page="view" />} />
          </Route>
          <Route
            path="results"
            element={
              <div className="w-full h-[75vh] flex items-center justify-center">
                <h1 className="text-3xl text-gray-500 font-bold">
                  Coming Soon
                </h1>
              </div>
            }
          />
        </Route>
        <Route path="parentDashboard" element={<ParentDashboardLayout />}>
          <Route index element={<ParentDashboard />} />
          <Route path=":studentId" element={<Outlet />}>
            <Route index element={<StudentLayout page="view" />} />
            <Route path="edit" element={<StudentLayout page="edit" />} />
            <Route
              path="addSubjects"
              element={<StudentLayout page="addSubjects" />}
            />
          </Route>
        </Route>

        {/* Content Creation Routes */}
        <Route path="contentCreation" element={<ContentCreationLayout />}>
          <Route index element={<Navigate to="questions" />} />
          <Route path="questions" element={<Outlet />}>
            <Route index element={<AllQuestions />} />
            <Route path="upload" element={<UploadQuestion />} />
            <Route path="edit/:id" element={<UploadQuestion />} />
          </Route>
          <Route path="text" element={<Outlet />}>
            <Route index element={"Text Content"} />
            <Route path="upload" element={"Upload Text Content"} />
          </Route>
          <Route path="document" element={<Outlet />}>
            <Route index element={"Document Content"} />
            <Route path="upload" element={"Upload Document Content"} />
          </Route>
          <Route path="video" element={<Outlet />}>
            <Route index element={"Video Content"} />
            <Route path="upload" element={"Upload Video Content"} />
          </Route>
        </Route>

        <Route path="/examLogin" element={<ExamLogin />} />
        {/* <Route
            path="/start"
            element={<CountDown />}
          /> */}
        <Route path="/examSetup" element={<Outlet />}>
          <Route index element={<Setup />} />
          <Route path=":step" element={<Setup />} />
        </Route>

        <Route path="/examInstructions" element={<ExamInstructions />} />
        <Route path="/examPage" element={<ExamPage />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/test" element={<Test />} />
      </Routes>

      {/* {recordingStopPopUp && 
				<div className='moduleCover'>
					<StopRecordingPopup
						setisScreenSharing={setisScreenSharing}
						setRecordingStopPopUp={setRecordingStopPopUp}
					/>	
				</div>
			} */}
    </>
  );
};

export default App;
