import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CustomLink from "./customLink";

import ContactForm from "./ContactForm";
import SliderRating from "./SliderRating";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GAEventTrack } from "../../hooks/GA_events";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const StudentLandingPage = ({ placement }) => {
  const navigate = useNavigate();
  const clickToScroll = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute("href");
    if (placement === "landingPage") {
      const targetElement = document.querySelector(targetId);
      const offset = targetElement.offsetTop;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    } else {
      navigate(`/${targetId}`);
    }
  };

  const devMode = false;

  const panel1 = useRef();
  const panel2 = useRef();
  const panel3 = useRef();
  const panel4 = useRef();
  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  useGSAP(() => {
    let mm = gsap.matchMedia();

    var t0 = gsap.timeline();
    t0.to("#feature-data", {
      scrollTrigger: {
        trigger: ".gsap-panel-1",
        start: "top bottom",
        markers: false,
        onEnter: () => {
          gsap.to("#feature-data", { background: "#FF8E1F" });
        },
      },
    });
    t0.to("#feature-data", {
      scrollTrigger: {
        trigger: ".gsap-panel-2",
        start: "top top",
        markers: false,
        onEnter: () => {
          gsap.to("#feature-data", { background: "#246CFF" });
        },
        onLeaveBack: () => {
          gsap.to("#feature-data", { background: "#FF8E1F" });
        },
      },
    });
    t0.to("#feature-data", {
      scrollTrigger: {
        trigger: ".gsap-panel-3",
        start: "top top",
        markers: false,
        onEnter: () => {
          gsap.to("#feature-data", { background: "#3DC1CA" });
        },
        onLeaveBack: () => {
          gsap.to("#feature-data", { background: "#246CFF" });
        },
      },
    });

    t0.to("#feature-data", {
      scrollTrigger: {
        trigger: ".gsap-panel-4",
        start: "top top",
        markers: false,
        onEnter: () => {
          gsap.to("#feature-data", { background: "#00256F" });
        },
        onLeaveBack: () => {
          gsap.to("#feature-data", { background: "#3DC1CA" });
        },
      },
    });

    mm.add("(min-width:992px)", (context) => {
      // panel 1
      gsap.timeline({
        scrollTrigger: {
          trigger: ".gsap-panel-1",
          scrub: 0.1,
          pin: true,
          pinSpacing: false,
          start: "top top",
          end: "bottom top",
          markers: false,
          onEnter: () => {
            panel1.current.classList.remove("g-fade-out");
            panel1.current.classList.add("g-fade-in");
          },
          onLeave: () => {
            panel1.current.classList.add("g-fade-out");
            panel1.current.classList.remove("g-fade-in");
          },
          onEnterBack: () => {
            panel1.current.classList.remove("g-fade-out");
            panel1.current.classList.add("g-fade-in");
          },
          onLeaveBack: () => {
            panel1.current.classList.add("g-fade-out");
            panel1.current.classList.remove("g-fade-in");
          },
        },
      });

      // panel 2
      gsap.timeline({
        scrollTrigger: {
          trigger: ".gsap-panel-2",
          scrub: 0.1,
          pin: true,
          pinSpacing: false,
          start: "top top",
          end: "bottom top",
          markers: false,
          onEnter: () => {
            panel2.current.classList.remove("g-fade-out");
            panel2.current.classList.add("g-fade-in");
          },
          onLeave: () => {
            panel2.current.classList.add("g-fade-out");
            panel2.current.classList.remove("g-fade-in");
          },
          onEnterBack: () => {
            panel2.current.classList.remove("g-fade-out");
            panel2.current.classList.add("g-fade-in");
          },
          onLeaveBack: () => {
            panel2.current.classList.add("g-fade-out");
            panel2.current.classList.remove("g-fade-in");
          },
        },
      });

      // panel 3
      gsap.timeline({
        scrollTrigger: {
          trigger: ".gsap-panel-3",
          scrub: 1,
          pin: true,
          pinSpacing: false,
          start: "top top",
          end: "bottom top",
          markers: false,
          onEnter: () => {
            panel3.current.classList.remove("g-fade-out");
            panel3.current.classList.add("g-fade-in");
          },
          onLeave: () => {
            panel3.current.classList.add("g-fade-out");
            panel3.current.classList.remove("g-fade-in");
          },
          onEnterBack: () => {
            panel3.current.classList.remove("g-fade-out");
            panel3.current.classList.add("g-fade-in");
          },
          onLeaveBack: () => {
            panel3.current.classList.add("g-fade-out");
            panel3.current.classList.remove("g-fade-in");
          },
        },
      });

      // panel 4
      gsap.timeline({
        scrollTrigger: {
          trigger: ".gsap-panel-4",
          scrub: 0.1,
          pin: true,
          pinSpacing: true,
          start: "top top",
          end: "bottom top",
          markers: false,
          onEnter: () => {
            panel4.current.classList.remove("g-fade-out");
            panel4.current.classList.add("g-fade-in");
          },
          onLeave: () => {
            panel4.current.classList.add("g-fade-out");
            panel4.current.classList.remove("g-fade-in");
          },
          onEnterBack: () => {
            panel4.current.classList.remove("g-fade-out");
            panel4.current.classList.add("g-fade-in");
          },
          onLeaveBack: () => {
            panel4.current.classList.add("g-fade-out");
            panel4.current.classList.remove("g-fade-in");
          },
        },
      });

      gsap.to("#tab1-pill", {
        scrollTrigger: {
          trigger: "#tab1",
          start: "top center",
          end: "bottom top",
          toggleClass: { targets: "#tab1-pill", className: "active" },
        },
      });
      gsap.to("#tab2-pill", {
        scrollTrigger: {
          trigger: "#tab2",
          start: "top top",
          end: "bottom top",
          toggleClass: { targets: "#tab2-pill", className: "active" },
        },
      });
      gsap.to("#tab3-pill", {
        scrollTrigger: {
          trigger: "#tab3",
          start: "top top",
          end: "bottom top",
          toggleClass: { targets: "#tab3-pill", className: "active" },
        },
      });
      gsap.to("#tab4-pill", {
        scrollTrigger: {
          trigger: "#tab4",
          start: "top top",
          end: "bottom top",
          toggleClass: { targets: "#tab4-pill", className: "active" },
        },
      });
      gsap.to("#tab5-pill", {
        scrollTrigger: {
          trigger: "#tab5",
          start: "top top",
          end: "bottom top",
          toggleClass: { targets: "#tab5-pill", className: "active" },
        },
      });
    });
  }); // useGSAP()

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || ""
  );
  const [showSection, setShowSection] = useState(
    localStorage.getItem("showSection") || ""
  );

  useEffect(() => {
    const tabObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const newActiveTab = entry.target.id;
          setActiveTab(newActiveTab);
          localStorage.setItem("activeTab", newActiveTab);
          document
            .querySelector(".feature-section")
            .classList.add("active-mode");
        }
      });
    });

    const secObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const newShowSection = entry.target.id;
            setShowSection(newShowSection);
            localStorage.setItem("showSection", newShowSection);
          }
        });
      },
      { rootMargin: "-50px 0px 0px 0px" } // Adjust rootMargin to trigger earlier when scrolling up
    );

    // Observe each tab-section
    document.querySelectorAll(".tab-section").forEach((tab) => {
      tabObserver.observe(tab);
    });

    // Observe each section
    document.querySelectorAll(".section").forEach((sec) => {
      secObserver.observe(sec);
    });

    // Clean up observer on component unmount
    return () => {
      tabObserver.disconnect();
      secObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset === 0) {
        document
          .querySelector(".feature-section")
          .classList.remove("active-mode");
      }
    };

    setTimeout(function () {
      ScrollTrigger.refresh();
    }, 2000);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleTabClick(name = "", e = null) {
    if (e) {
      e.preventDefault();
    }
    let el = document.getElementById(name);
    if (el) {
      let top = el.getBoundingClientRect().top;
      let y = top + window.scrollY;
      if (top < 0) {
        y = y - 10;
      } else {
        y = y + 10;
      }
      // console.log(name, y, top, window.scrollY);
      window.scroll({
        top: y,
        behavior: "smooth",
      });
    }
  }

  // back to top button set
  useEffect(() => {
    const handleScroll = () => {
      const scrollToTopBtn = document.querySelector(".scrollToTopBtn");
      const rootElement = document.documentElement;

      if (rootElement.scrollTop >= 300) {
        scrollToTopBtn.classList.add("showBtn");
      } else {
        scrollToTopBtn.classList.remove("showBtn");
      }
    };

    const scrollToTop = () => {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const scrollToTopBtn = document.querySelector(".scrollToTopBtn");
    scrollToTopBtn.addEventListener("click", scrollToTop);
    document.addEventListener("scroll", handleScroll);

    return () => {
      scrollToTopBtn.removeEventListener("click", scrollToTop);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Animate the #banner-cloud element
    gsap.to("#banner-cloud", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 0.5,
    });

    // Animate the #banner-heading element
    gsap.to("#banner-heading", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 0.75,
    });

    gsap.fromTo(
      "#events-first",
      { x: "10%" }, // Start off-screen to the right
      {
        duration: 1,
        x: "0%", // Move to its original position
        ease: "Power1.easeInOut",
        yoyo: true,
        repeat: -1, // Infinite repeat
        delay: 0.5,
      }
    );
  }, []);

  gsap.to("#build-wrapper-img", {
    duration: 1,
    y: "-=12",
    ease: "Power1.easeInOut",
    yoyo: true,
    repeat: -1,
    delay: 0.5,
    autoAlpha: 1, // Ensures the element is visible initially
  });

  gsap.from("#build-wrapper-img", {
    duration: 1.5,
    y: 20,
    autoAlpha: 0,
    ease: "Power1.easeInOut",
    delay: 0.5,
    scrollTrigger: {
      trigger: "#build-wrapper-img",
      start: "top 80%", // Adjust the start position as needed
      toggleActions: "play none none none",
    },
  });

  const buildSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          autoplay: true,
        },
      },
    ],
  };

  //loader code
  const [loaderVisible, setLoaderVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaderVisible(false);
      window.scrollTo(0, 0);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <>
      {loaderVisible && (
        <div className="home-loader">
          <img src="/img/loader.gif" alt="Loading" />
        </div>
      )}
      <div className="back-to-top">
        <button className="scrollToTopBtn">
          <img src="/img/new-home/back-to-top.png" alt="back-to-top" />
        </button>
      </div>
      <section className="banner-section home-banner-section student-banner">
        <div className="container">
          <div className="row register-section">
            <div className="flex items-center justify-center my-10">
              <h4 className="col-md-12 col-lg-7 text-center">
                The only way to do great work is to love what you do and to
                practice it endlessly.
                <span className="block text-xl font-thin mt-2">
                  - Steve Jobs
                </span>
              </h4>
            </div>

            {/* <div className="col-md-12 col-lg-6">
              <div className="content">
                <div className="banner-img">
                  <img
                    id="banner-cloud"
                    className="banner-cloud"
                    src="/img/new-home/banner-cloud.png"
                    alt="banner"
                  />
                  <img
                    id="banner-heading"
                    className="banner-heading"
                    src="/img/new-home/banner-heading.png"
                    alt="banner"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-6 right-item">
              <h4 data-aos="zoom-in" data-aos-delay="200">
                Test your knowledge and win fantastic prizes in our Practido
                Online Olympiad!
              </h4>
              <div className="btn-space">
                <button
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  data-aos-duration="800"
                  type="button"
                  className="btn primary-btn"
                  onClick={() => {
                    GAEventTrack({
                      event: "register_btn_click",
                      data: { button_name: "Register Now" },
                    });

                    window.open("/register", "_blank");
                  }}
                >
                  Register Now
                </button>
              </div>
            </div> */}
          </div>
          {/* row */}
        </div>
        <div className="student-blue-wrapper">
          <div className="container">
            {/* <h4>
              The only way to do great work is to love what you do and to
              practice it endlessly.<span>-Steve Jobs</span>
            </h4> */}
            <div className="book-wrapper">
              <img
                id="banner-cloud"
                className="banner-cloud book-icon"
                src="/img/student-home/book-icon.png"
                alt="book"
              />
              <img
                id="banner-heading"
                className="banner-heading book-circle"
                src="/img/student-home/book-circle.png"
                alt="banner"
              />
            </div>
            <h3>
              Learning is an adventure, but practice can feel like a chore. We
              bridge that gap!
            </h3>
            <p>
              Our vision is simple: to make practice fun! Students are quick to
              learn new things, but often find practice boring. That's where we
              step in. While learning is exciting, mastering a skill requires
              practice, which can sometimes lead to boredom and quitting. That's
              why we're here—
              <span>
                to offer a unique practice experience that keeps students
                engaged and motivated.
              </span>{" "}
              Unlike other learning apps, we focus solely on practice along with
              learning, ensuring students have the tools they need to succeed.
            </p>
            <p className="d-none">
              <span className="bold">Free Learning Starts Now!</span> Download
              App:
            </p>
            <div className="banner-icon d-none">
              <span>
                <img src="/img/student-home/white-apple.png" alt="apple" />
              </span>
              <span>
                <img
                  src="/img/student-home/white-play-store.png"
                  alt="play-store"
                />
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="resource-section">
        <div className="container aos-animate" data-aos="fade-up">
          <div
            className="head"
            data-aos="fade-up"
            data-aos-delay="150"
            data-aos-duration="500"
          >
            <h3>
              Your All-in-One Resource
              <span>
                Discover how Practido benefits Schools, Educators, Parents, and
                Students alike, providing a comprehensive solution for academic
                success.
              </span>
            </h3>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <div data-aos="zoom-in" className="img-box">
                <img src="/img/student-home/rec-3.png" alt="schools" />
              </div>
              <h4>Schools</h4>
              <p>
                Offer your students an unparalleled learning experience with the
                Practido Online Olympiad. Our expert-designed assessments in
                English, Maths, and Science provide a flexible, secure platform
                for academic excellence. Schools joining us will also enjoy
                discounted access to future initiatives like teacher training,
                engaging lab activities, learning app, school management app,
                etc.
              </p>
              <CustomLink
                to="/schoollanding"
                type="button"
                className="btn link-btn"
              >
                Explore Now
              </CustomLink>
            </div>
            <div className="col-sm-12 col-md-4">
              <div data-aos="zoom-in" className="img-box">
                <img src="/img/student-home/rec-2.png" alt="educators" />
              </div>
              <h4>Educators</h4>
              <p>
                Join us in crafting exceptional educational content for
                students. Share your expertise in English, Maths, and Science,
                and earn competitive rewards, including royalty on your content
                contributions. Contribute to a dynamic learning platform that
                values your knowledge and impact millions of young minds. Shape
                the Future of Learning with Practido.
              </p>
              <CustomLink
                to="/educatorlanding"
                type="button"
                className="btn link-btn"
              >
                Explore Now
              </CustomLink>
            </div>
            <div className="col-sm-12 col-md-4">
              <div data-aos="zoom-in" className="img-box">
                <img src="/img/student-home/rec-1.png" alt="parents" />
              </div>
              <h4>Parents</h4>
              <p>
                Practido empowers parents to actively engage in their child's
                learning journey by providing tools to track progress and assign
                tasks. With our platform, parents can monitor their child's
                performance, assign tasks, and support their academic
                development from anywhere, anytime.
              </p>
              <CustomLink
                to="/parentlanding"
                type="button"
                className="btn link-btn"
              >
                Explore Now
              </CustomLink>
            </div>
          </div>
        </div>
      </section>

      <section className="feature-section">
        <h3 className="pd main-head">
          Features That Make Learning Fun!
          <span>
            Explore the exciting features of our app designed to enhance your
            kid’s learning experience
          </span>
        </h3>
        <div id="feature-data" className="feature-data">
          <div className={`tab-section`} id="tab1">
            <div
              id="section1"
              className="gsap-panel gsap-panel-1 section feature-wrapper mb-0 show"
            >
              <div className="container g-fade-out" ref={panel1}>
                <div id="practice" className="feature-box row aos-animate">
                  <div
                    className="col-md-12 col-lg-5 center"
                    data-aos="fade-right"
                    data-aos-delay="800"
                  >
                    <img
                      className="feature-img"
                      src="/img/student-home/feature-1.png"
                      alt="apple"
                    />
                  </div>
                  <div
                    className="col-md-12 col-offset-1 col-lg-6 para-box"
                    data-aos="fade-left"
                    data-aos-delay="800"
                  >
                    <h3>Topic Based Practice Tests</h3>
                    <p>
                      From Math to English and Science, our feature empowers
                      your child to practice a variety of topics. They can
                      select their areas of interest and improve at their own
                      pace. With interactive exercises and constructive
                      feedback, practicing alongside learning becomes engaging
                      and effective.
                    </p>
                    <h4>Free Learning Starts Now!</h4>
                    <p>Download App:</p>
                    <div className="banner-icon">
                      <span>
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice"
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src="/img/student-home/google-play.png"
                            alt="google-play"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="gsap-panel gsap-panel-2 section feature-wrapper mb-0 show"
              id="section2"
            >
              <div className="container g-fade-out" ref={panel2}>
                <div className="feature-box row aos-animate">
                  <div
                    className="col-md-12 col-lg-5 center"
                    data-aos="fade-right"
                    data-aos-delay="800"
                  >
                    <img
                      className="feature-img"
                      src="/img/student-home/feature-2.png"
                      alt="apple"
                    />
                  </div>
                  <div
                    className="col-md-12 col-offset-1 col-lg-6 para-box"
                    data-aos="fade-left"
                    data-aos-delay="800"
                  >
                    <h3>Master Subjects with Timed Tests!</h3>
                    <p>
                      Prepare for exams with our Model Tests feature! Students
                      can select a subject and take a timed test comprising 35
                      random questions from various topics. This hour-long model
                      test challenges their knowledge and time management
                      skills, ensuring comprehensive preparation for academic
                      success.
                    </p>
                    <h4>Free Learning Starts Now!</h4>
                    <p>Download App:</p>
                    <div className="banner-icon">
                      <span>
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice"
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src="/img/student-home/google-play.png"
                            alt="google-play"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`gsap-panel gsap-panel-3 section feature-wrapper mb-0 ${
                showSection === "section3" ? "show" : "hide"
              }`}
              id="section3"
            >
              <div className="container g-fade-out" ref={panel3}>
                <div className="feature-box row aos-animate">
                  <div
                    className="col-md-12 col-lg-5 center"
                    data-aos="fade-right"
                    data-aos-delay="800"
                  >
                    <img
                      className="feature-img"
                      src="/img/student-home/feature-3.png"
                      alt="apple"
                    />
                  </div>
                  <div
                    className="col-md-12 col-offset-1 col-lg-6 para-box"
                    data-aos="fade-left"
                    data-aos-delay="800"
                  >
                    <h3>Explore Your Test History!</h3>
                    <p>
                      Explore detailed test histories effortlessly. Delve into
                      past tests, average scores, topics practiced, and
                      topic-wise scores. Identify strengths and areas for
                      improvement with ease. Additionally, review correct and
                      incorrect answers for targeted learning. With our
                      comprehensive test history feature, guiding academic
                      progress is simplified!
                    </p>
                    <h4>Free Learning Starts Now!</h4>
                    <p>Download App:</p>
                    <div className="banner-icon">
                      <span>
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice"
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src="/img/student-home/google-play.png"
                            alt="google-play"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`gsap-panel gsap-panel-4 section feature-wrapper mb-0 ${
                showSection === "section4" ? "show" : "hide"
              }`}
              id="section4"
            >
              <div className="container g-fade-out" ref={panel4}>
                <div className="feature-box row aos-animate">
                  <div
                    className="col-md-12 col-lg-5 center"
                    data-aos="fade-right"
                    data-aos-delay="800"
                  >
                    <img
                      className="feature-img"
                      src="/img/student-home/feature-4.png"
                      alt="apple"
                    />
                  </div>
                  <div
                    className="col-md-12 col-offset-1 col-lg-6 para-box"
                    data-aos="fade-left"
                    data-aos-delay="800"
                  >
                    <h3>View Your Results Instantly!</h3>
                    <p>
                      With Practido, accessing your results is quick and
                      convenient. Instantly see your scores, share them with
                      friends, revisit the test for further practice, review
                      your answers, and even generate a PDF of your test for
                      future reference. Stay informed, track your progress, and
                      improve with ease!"
                    </p>
                    <h4>Free Learning Starts Now!</h4>
                    <p>Download App:</p>
                    <div className="banner-icon">
                      <span>
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice"
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src="/img/student-home/google-play.png"
                            alt="google-play"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tab-section */}

          <div className="tab-section ">
            <div className="section purple-back mb-0 feature-wrapper">
              <div className="container">
                <div className="feature-box row pd100">
                  <div className="col-offset-2 col-md-8 para-box">
                    <h3 className="center">My World</h3>
                    <p className="center">
                      Immerse yourself in thrilling events like the Practido
                      Olympiads, challenge your friends to friendly
                      competitions, and strive for a place in the esteemed Hall
                      of Fame.
                    </p>
                  </div>
                  <div className="col-md-12 purple-col">
                    <div className="left-img-frame aos-animate">
                      <p data-aos="fade-left" data-aos-delay="200">
                        Exciting Events
                      </p>
                      <img
                        data-aos="fade-left"
                        data-aos-delay="250"
                        className="img-1"
                        src="/img/student-home/purple-img-4.png"
                        alt="event"
                      />
                      <img
                        data-aos="fade-left"
                        data-aos-delay="300"
                        className="img-4"
                        src="/img/student-home/purple-img-5.png"
                        alt="event"
                      />
                    </div>
                    <div className="left-img-frame aos-animate">
                      <p data-aos="fade-left" data-aos-delay="200">
                        Summary
                      </p>
                      <img
                        data-aos="fade-left"
                        data-aos-delay="250"
                        className="img-3"
                        src="/img/student-home/purple-img-3.png"
                        alt="summery"
                      />
                      <img
                        className="img-5"
                        data-aos="fade-left"
                        data-aos-delay="300"
                        src="/img/student-home/purple-img-6.png"
                        alt="summery"
                      />
                    </div>
                    <div className="left-img-frame aos-animate">
                      <p data-aos="fade-left" data-aos-delay="200">
                        Milestones
                      </p>
                      <img
                        className="img-1"
                        data-aos="fade-left"
                        data-aos-delay="250"
                        src="/img/student-home/purple-img-2.png"
                        alt="milestones"
                      />
                      <img
                        className="img-2"
                        data-aos="fade-left"
                        data-aos-delay="300"
                        src="/img/student-home/purple-img-1.png"
                        alt="milestones"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tab-section */}

          <div className="tab-section">
            <div className="section pink-back mb-0 feature-wrapper build-section">
              <div id="reward" className="build-wrapper-box">
                <div className="floating-img">
                  <img
                    className="floating-animation reward-0"
                    src="/img/student-home/build-back-sm-0.png"
                    alt="images"
                  />
                  <img
                    className="floating-animation reward-1"
                    src="/img/student-home/build-back-sm-1.png"
                    alt="images"
                  />
                  <img
                    className="floating-animation reward-2"
                    src="/img/student-home/build-back-sm-2.png"
                    alt="images"
                  />
                  <img
                    className="floating-animation reward-3"
                    src="/img/student-home/build-back-sm-3.png"
                    alt="images"
                  />
                  <img
                    className="floating-animation reward-4"
                    src="/img/student-home/build-back-sm-4.png"
                    alt="images"
                  />
                  <img
                    className="floating-animation reward-5"
                    src="/img/student-home/build-back-sm-5.png"
                    alt="images"
                  />
                  <img
                    className="floating-animation reward-6"
                    src="/img/student-home/build-back-sm-4.png"
                    alt="images"
                  />
                  <img
                    className="floating-animation reward-7"
                    src="/img/student-home/build-back-sm-2.png"
                    alt="images"
                  />
                  <img
                    className="floating-animation reward-8"
                    src="/img/student-home/build-back-sm-1.png"
                    alt="images"
                  />
                  <img
                    className="floating-animation reward-9"
                    src="/img/student-home/build-back-sm-6.png"
                    alt="images"
                  />
                  <img
                    className="floating-animation reward-10"
                    src="/img/student-home/build-back-sm-7.png"
                    alt="images"
                  />
                </div>
                <div className="build-wrapper">
                  <div className="container">
                    <img
                      className="img-1"
                      data-aos="fade-up"
                      src="/img/student-home/build-mobile-3.png"
                      alt="streaks"
                    />
                    <img
                      className="img-1 img-2"
                      data-aos="fade-up"
                      src="/img/student-home/build-mobile-2.png"
                      alt="streaks"
                    />
                    <img
                      className="img-1 img-3"
                      data-aos="fade-up"
                      src="/img/student-home/build-mobile-1.png"
                      alt="streaks"
                    />
                    <img
                      className="img-1 img-4"
                      data-aos="fade-up"
                      src="/img/student-home/build-mobile-4.png"
                      alt="streaks"
                    />
                    <div className="data">
                      <h3>Key Features to Enhance Your Learning Journey</h3>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="shimmer-wrapper">
                            <img
                              src="/img/student-home/build-sm-3.png"
                              alt="Points"
                            />
                          </div>
                          <h4>Practice Points</h4>
                          <p>
                            Earn points by taking practice tests, climb the
                            leaderboard, and collect trophies for major
                            milestones.
                          </p>
                        </div>
                        <div className="col-md-3">
                          <div className="shimmer-wrapper">
                            <img
                              src="/img/student-home/build-sm-2.png"
                              alt="Consistency"
                            />
                          </div>
                          <h4>Coins of Consistency</h4>
                          <p>
                            Earn coins through learning activities and unlock
                            exclusive content and features.
                          </p>
                        </div>
                        <div className="col-md-3">
                          <div className="shimmer-wrapper">
                            <img
                              src="/img/student-home/build-sm-4.png"
                              alt="Trophies"
                            />
                          </div>
                          <h4>Earn Trophies</h4>
                          <p>
                            Scale the leaderboard with Practido Points, earn
                            trophies for achievements, and excel in your
                            learning journey.
                          </p>
                        </div>
                        <div className="col-md-3">
                          <div className="shimmer-wrapper">
                            <img
                              src="/img/student-home/build-sm-1.png"
                              alt="streaks"
                            />
                          </div>
                          <h4>Practice Streaks</h4>
                          <p>
                            Build consistency and boost success with our streak
                            feature, rewarding daily engagement.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mobile-build-box">
                  <div className="build-wrapper-mobile">
                    <div className="data">
                      <div className="row">
                        <Slider className="col-lg-12" {...buildSlider}>
                          <div className="slider-item">
                            <img
                              className="img-1"
                              src="/img/student-home/build-mobile-3.png"
                              alt="Points"
                            />
                            <div className="slider-data">
                              <h3>
                                Key Features to Enhance Your Learning Journey
                              </h3>
                              <div className="shimmer-wrapper">
                                <img
                                  src="/img/student-home/build-sm-3.png"
                                  alt="Points"
                                />
                              </div>
                              <h4>Practice Points</h4>
                              <p>
                                Earn points by taking practice tests, climb the
                                leaderboard, and collect trophies for major
                                milestones.
                              </p>
                            </div>
                          </div>
                          <div className="slider-item item-1">
                            <img
                              className="img-1 img-2"
                              src="/img/student-home/build-mobile-2.png"
                              alt="Consistency"
                            />
                            <div className="slider-data">
                              <h3>
                                Key Features to Enhance Your Learning Journey
                              </h3>
                              <div className="shimmer-wrapper">
                                <img
                                  src="/img/student-home/build-sm-2.png"
                                  alt="Consistency"
                                />
                              </div>
                              <h4>Coins of Consistency</h4>
                              <p>
                                Earn coins through learning activities and
                                unlock exclusive content and features.
                              </p>
                            </div>
                          </div>
                          <div className="slider-item item-2">
                            <img
                              className="img-1 img-4"
                              src="/img/student-home/build-mobile-4.png"
                              alt="streaks"
                            />
                            <div className="slider-data">
                              <h3>
                                Key Features to Enhance Your Learning Journey
                              </h3>
                              <div className="shimmer-wrapper">
                                <img
                                  src="/img/student-home/build-sm-4.png"
                                  alt="Trophies"
                                />
                              </div>
                              <h4>Earn Trophies</h4>
                              <p>
                                Scale the leaderboard with Practido Points, earn
                                trophies for achievements, and excel in your
                                learning journey.
                              </p>
                            </div>
                          </div>
                          <div className="slider-item">
                            <img
                              className="img-1 img-3"
                              src="/img/student-home/build-mobile-1.png"
                              alt="Trophies"
                            />
                            <div className="slider-data">
                              <h3>
                                Key Features to Enhance Your Learning Journey
                              </h3>
                              <div className="shimmer-wrapper">
                                <img
                                  src="/img/student-home/build-sm-1.png"
                                  alt="streaks"
                                />
                              </div>
                              <h4>Practice Streaks</h4>
                              <p>
                                Build consistency and boost success with our
                                streak feature, rewarding daily engagement.
                              </p>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tab-section */}

          <div className="tab-section">
            <div className="section orange-back mb-0 feature-wrapper mon-pd">
              <div className="container">
                <div id="monitor" className="feature-box row aos-animate">
                  <div
                    className="col-md-12 col-lg-5 para-box mon-para"
                    data-aos="fade-right"
                  >
                    <h3>Instant Monitoring</h3>
                    <p className="left">
                      Track student progress in real-time for proactive academic
                      guidance.
                    </p>
                    <h4>Free Learning Starts Now!</h4>
                    <p className="left">Download App:</p>
                    <div className="banner-icon">
                      <span>
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice"
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src="/img/student-home/google-play.png"
                            alt="google-play"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-7 center monitor-img-box">
                    <div className="monitor child-4" data-aos="fade-left">
                      <img src="/img/student-home/monitor-4.png" alt="images" />
                    </div>
                    <div className="monitor" data-aos="fade-up">
                      <img src="/img/student-home/monitor-5.png" alt="images" />
                    </div>
                    <div className="monitor child-5" data-aos="fade-up">
                      <img src="/img/student-home/monitor-6.png" alt="images" />
                    </div>
                    <div className="monitor child-3" data-aos="fade-up">
                      <img src="/img/student-home/monitor-2.png" alt="images" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tab-section */}

          <div className="tab-section">
            <div className="section sky-blue-back mb-0 feature-wrapper parent-challenge">
              <div className="container">
                <div className="feature-box row aos-animate pd0 order-change">
                  <div
                    className="col-md-12 col-offset-1 col-lg-6  para-box"
                    data-aos="fade-right"
                  >
                    <h3>Join Top Performers</h3>
                    <p className="left">
                      Join the ranks of our top performers and celebrate
                      achievements with our interactive leaderboard! Engage in
                      friendly participation, track your standing, and inspire
                      others as you climb the ranks toward academic excellence.
                    </p>
                    <h4>Free Learning Starts Now!</h4>
                    <p className="left">Download App:</p>
                    <div className="banner-icon">
                      <span>
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice"
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src="/img/student-home/google-play.png"
                            alt="google-play"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-12 col-lg-5 center"
                    data-aos="fade-left"
                  >
                    <img
                      className="feature-img"
                      src="/img/student-home/feature-5.png"
                      alt="feature"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tab-section */}
        </div>
        {/* feature-data */}
      </section>

      <SliderRating contactFormRef={contactFormRef} />
      <ContactForm contactFormRef={setFormRef} />
    </>
  );
};

export default StudentLandingPage;
